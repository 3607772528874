/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-02",
    versionChannel: "nightly",
    buildDate: "2023-06-02T00:26:29.882Z",
    commitHash: "6bc5d7299513111cc5f5bb6cb2cddc4d86ac7266",
};
